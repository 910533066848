var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-10 pt-12 h-full"},[_c('h2',{staticClass:"mb-10"},[_vm._v("Account Details")]),_c('ValidationObserver',{ref:"signUpForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',[_c('ValidationProvider',{attrs:{"mode":"eager","rules":"required|max:255","vid":"name","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"form-input",class:errors.length ? 'border border-red-600' : '',attrs:{"type":"text","placeholder":"Your name"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}}),(errors.length)?_c('p',{staticClass:"w-full text-red-600 mt-1 text-sm"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"mt-5"},[_c('ValidationProvider',{attrs:{"mode":"eager","rules":"required|email|max:255","vid":"email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-input",class:errors.length ? 'border border-red-600' : '',attrs:{"type":"email","placeholder":"yourmail@mail.com"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),(errors.length)?_c('p',{staticClass:"w-full text-red-600 mt-1 text-sm"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"mt-6"},[_c('ValidationProvider',{attrs:{"mode":"eager","rules":"required|min:8|max:255","vid":"password","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-input",class:errors.length ? 'border border-red-600' : '',attrs:{"type":"password","placeholder":"Your password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),(errors.length)?_c('p',{staticClass:"w-full text-red-600 mt-1 text-sm"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"mt-6"},[_c('ValidationProvider',{attrs:{"mode":"eager","rules":"required|confirmed:password|min:8|max:255","vid":"password_confirmation","name":"Password confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.confirm_password),expression:"form.confirm_password"}],staticClass:"form-input",class:errors.length ? 'border border-red-600' : '',attrs:{"type":"password","placeholder":"Confirm your password"},domProps:{"value":(_vm.form.confirm_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "confirm_password", $event.target.value)}}}),(errors.length)?_c('p',{staticClass:"w-full text-red-600 mt-1 text-sm"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"absolute px-10 w-full left-0 right-0 bottom-0"},[_c('button',{staticClass:"btn-primary text-white bg-lightPurple w-full capitalize text-base block text-center",class:{'opacity-75': _vm.loading},attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.loading ? 'Signing Up...' : 'Continue')+" ")])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }