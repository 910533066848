<template>
  <div class="px-10 pt-12 h-full">
    <h2 class="mb-10">Account Details</h2>
    <ValidationObserver ref="signUpForm" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div>
          <ValidationProvider mode="eager" rules="required|max:255" vid="name" name="Name" v-slot="{ errors }">
            <input
              v-model="form.name"
              type="text"
              class="form-input"
              :class="errors.length ? 'border border-red-600' : ''"
              placeholder="Your name"
            />
            <p v-if="errors.length" class="w-full text-red-600 mt-1 text-sm"> 
              {{ errors[0] }} 
            </p>
          </ValidationProvider>
        </div>

        <div class="mt-5">
          <ValidationProvider mode="eager" rules="required|email|max:255" vid="email" name="Email" v-slot="{ errors }">
            <input 
              v-model="form.email"
              type="email" 
              class="form-input"
              :class="errors.length ? 'border border-red-600' : ''"
              placeholder="yourmail@mail.com"
            />
            <p v-if="errors.length" class="w-full text-red-600 mt-1 text-sm"> 
              {{ errors[0] }} 
            </p>
          </ValidationProvider>
        </div>

        <div class="mt-6">
          <ValidationProvider mode="eager" rules="required|min:8|max:255" vid="password" name="Password" v-slot="{ errors }">
            <input
              v-model="form.password"
              type="password"
              class="form-input"
              :class="errors.length ? 'border border-red-600' : ''"
              placeholder="Your password"
            />
            <p v-if="errors.length" class="w-full text-red-600 mt-1 text-sm"> 
              {{ errors[0] }} 
            </p>
          </ValidationProvider>
        </div>

        <div class="mt-6">
          <ValidationProvider mode="eager" rules="required|confirmed:password|min:8|max:255" vid="password_confirmation" name="Password confirmation" v-slot="{ errors }">
            <input
              v-model="form.confirm_password"
              type="password"
              class="form-input"
              :class="errors.length ? 'border border-red-600' : ''"
              placeholder="Confirm your password"
            />
            <p v-if="errors.length" class="w-full text-red-600 mt-1 text-sm"> 
              {{ errors[0] }} 
            </p>
          </ValidationProvider>
        </div>

        <div class="absolute px-10 w-full left-0 right-0 bottom-0">
          <button
            class="btn-primary text-white bg-lightPurple w-full capitalize text-base block text-center"
            type="submit"
            :class="{'opacity-75': loading}"
          >
            {{ loading ? 'Signing Up...' : 'Continue'}}
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';

import { SET_FORM } from '@/store/types/mutations';
import { SIGN_UP } from '@/store/types/actions';

export default {
  name: "SignUpName",

  data: () => ({
    loading: false, 

    form: {
      name: '',
      email: '',
      passowrd: '',
      confirm_password: '',
    }
  }),

  methods: {
    ...mapMutations({
      setForm: `auth/${SET_FORM}`,
    }),

    ...mapActions({
      signUp: `auth/${SIGN_UP}`,
    }),

    submit() {
      this.loading = true

      this.setForm(this.form)

      this.signUp()
        .then(() => {
          this.$store.dispatch('auth/fetchStats')
          this.$router.push({ name:'home' });
        })
        .catch(errors => {
          this.loading = false
          this.$refs.signUpForm.setErrors(errors)

          this.$ionic
            .toastController.create({
              color: 'danger',
              message: 'There was an error while trying to sign you in',
              duration: 3000,
            })
            .then(toast => toast.present())
        })
    }
  }
}
</script>
